// @flow
import React from 'react';
import { Location } from '@reach/router';
import SEO from '../components/SEO';
import { titlePathnameMap } from '../constants';
import styles from '../styles/pages/landing.module.scss';
import ConversionTop from '../components/Landing/ConversionTop';
import Point from '../components/Landing/Point';
import Step from '../components/Landing/Step';
import Can from '../components/Landing/Can';
import ConversionBottom from '../components/Landing/ConversionBottom';
import Plus from '../components/Landing/Plus';
import Useful from '../components/Landing/Useful';
import Footer from '../components/Landing/Footer';
import TopBanner from '../components/Landing/TopBanner';

const LandingPage = () => {
  return (
    <div className={styles.landing}>
      <Location>
        {({ location }) => (
          <>
            {titlePathnameMap[location.pathname] && (
              <SEO
                title={titlePathnameMap[location.pathname]}
                pathname={location.pathname}
                audience="consumer"
              />
            )}
            <TopBanner />
            <>
              <ConversionTop />
              <Point />
              <Step />
              <Can />
              <Plus />
              <ConversionBottom />
              <Useful />
              <Footer />
            </>
          </>
        )}
      </Location>
    </div>
  );
};

export default LandingPage;
